import { Box, Card as MuiCard, CardMedia  } from "@mui/material";
import { Button, Card, Grid, Icon, Modal, ModalActions, ModalTitle, Spinner, Typography } from "@platform-ui/design-system";
import React, { useEffect, useRef, useState } from "react";
import Carousel from 'react-material-ui-carousel'
import { useFlipperHook } from '../Tables/helpers';
import Connect from '../Connect/Connect';
import { IADState } from '../IntegrationApps/IntegrationAppDetails/state';
import { useStoreState } from '../Store';

interface IntegrationAppDetailsOverviewProps {
  title: string;
  description: string;
  images: string[];
}

const IntegrationAppDetailsOverview = ({
    title,
    description,
    images,
}: IntegrationAppDetailsOverviewProps) => {
  const [zigzagOverviewEnabled, loading] = useFlipperHook('zigzag_overview');
  const placeholderImages = [Connect.public_path() + '/placeholders/1.png', Connect.public_path() + '/placeholders/2.png', Connect.public_path() + '/placeholders/3.png', Connect.public_path() + '/placeholders/4.png', Connect.public_path() + '/placeholders/5.png',  Connect.public_path() + '/placeholders/6.png', Connect.public_path() + '/placeholders/7.png', Connect.public_path() + '/placeholders/8.png', Connect.public_path() + '/placeholders/9.png']

  // PLEA(Xander): May the Omnissiah forgive me for this regex ≡][≡
  const regex = /<([hH]\d)(.*?)>(.*?)<\/\1>\s*((?:<(div|strong|span|p).*?>.*?<\/(div|strong|span|p)>\s*)*)/gs;
  const matches = description.match(regex);
  const [openRequestDemoModal, setRequestDemoModal] = useState(false);
  const connect: Connect = (window as any).connect;
  const state = useStoreState() as IADState;
  
  const handleOpenRequestDemoModal = async () => {
    const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
    const options = {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      }),
      body: JSON.stringify({
        'script_path': 'f/IH_supporting_flows_and_scripts/integration_hub_customer_call_to_action',
        'params': {
          'action': 'request_demo',
          'integration_app': title
        },
        'async': true
      })
    };
    try {
      if (connect.tenant["connect_id"] == "unknown") {
        // NOTE(Michael): using staging 9191 tenant for catch all if just from no tenant
        if (window.location.hostname.includes('staging')) {
          var path = `/integration_apps/423afcfd-9aa9-492f-b37d-fffc604408a7/windmill_run`
        } else {
          var path = `/integration_apps/f2db7572-7867-4d74-8cb5-a4fb2dba7764/windmill_run`
        }
      }
      else {
        var path = `/integration_apps/${connect.tenant["connect_id"]}/windmill_run`
      }
      if (!window.location.host.includes('connect') && !window.location.host.includes('localhost')) {
        path = '/services/connect' + path;
      }
      const response = await fetch(path, options);
      const data = await response.json();
      setRequestDemoModal(true);
    } catch (error) {
      Connect.log(error);
    }
  };

  return (
    loading ? <Spinner /> 
    :
    <>
      <Modal
        id='demo-request-modal'
        open={openRequestDemoModal}
        dsOnClose={() => setRequestDemoModal(false)}
        body={"Thank you for your request. We will have someone from our sales team reach out to you shortly to discuss your needs and provide further assistance."}
        header={
          <ModalTitle dsOnClose={() => setRequestDemoModal(false)}>Demo Requested</ModalTitle>
        }
        footer={
          <ModalActions></ModalActions>
        }
        disableBackdropClick
      />
      <Card
        id=""
        header="About the Connector"
        headerAction={
          Object.keys(state.settingsHash).length == 0 && <Button body='Request a Demo' tooltip='Request a Demo' dsOnClick={() => handleOpenRequestDemoModal()} />
        }
        titleBar={true}
        body={
          <Grid>
            <Typography variant="subtitle1">{title}</Typography>
            <div dangerouslySetInnerHTML={{__html: description}} />
            {images.length != 0 && <Grid>
              <Carousel sx={{width: "100%", justifyContent: "center", alignItems: "center"}} autoPlay={false} animation='slide' NextIcon={<Icon body='navigate_next' variant='filled' color='primary'/>} PrevIcon={<Icon body='navigate_before' variant='filled' color='primary'/>}>
                {images && images.map(image => {
                  return<CarouselImage image={image}/>
                })}
              </Carousel>
            </Grid>}
          </Grid>
        }
      />
     </>
  );
}

function CarouselImage(props) {
  return (
  <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
    <MuiCard sx={{display: "contents"}}>
       <CardMedia
         component="img"
         image={props.image}
         sx={{width: "80%", height: "80%"}}
       />
    </MuiCard>
  </Box>
 )
}

function ZigzagBlock(props) {
  const isEven = props.index % 2 == 0
  const image = props.image
  const muiObjectRef = useRef(null);
  const [muiObjectDimensions, setMuiObjectDimensions] = React.useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const { current } = muiObjectRef;
    if (current) {
      const rect = current.getBoundingClientRect();
      setMuiObjectDimensions({
        width: rect.width,
        height: rect.height,
      });
    }
  }, [(muiObjectRef.current && muiObjectRef.current.getBoundingClientRect())]);

  return (<>
    <Grid item xs={isEven ? 3 : 4} sx={{textAlign:'right'}}>{!isEven && image && <img src={image} className='zigzag-image' height={muiObjectDimensions.height} width={muiObjectDimensions.height}></img>}</Grid>
    <Grid item xs={5} >
      <MuiCard ref={muiObjectRef} className='zigzag-card'>
        <div dangerouslySetInnerHTML={{__html: props.match}} className="zigzag-content" />
      </MuiCard>
    </Grid>
    <Grid item xs={isEven ? 4 : 3} sx={{textAlign:'left'}}>{isEven && image && <img src={image} className='zigzag-image' height={muiObjectDimensions.height} width={muiObjectDimensions.height}></img>}</Grid>
  </>)
}

export default IntegrationAppDetailsOverview