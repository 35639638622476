import { Box, ChipProps, CircularProgress, MenuItem, TextField } from '@mui/material';
import { Alert, Button, Card, Chip, Grid, Table, ToastController, Typography, designTokens } from '@platform-ui/design-system';
import React, { FC, useEffect, useMemo, useState } from 'react';
import Connect from '../Connect/Connect';
import CronBuilder from '../CronBuilder/CronBuilder';
import { IADState } from '../IntegrationApps/IntegrationAppDetails/state';
import { useStoreState } from '../Store';
import { ExecutionModal } from './ExecutionModal';
import { NextRunModal } from './NextRunModal';
import { TasksModal } from './TasksModal';

interface ExecutionProps {
  connectorName: string;
}

export interface Workflow {
  id: number;
  name: string;
  created_at: string;
  active_version_id: number;
  scheduled_trigger: boolean;
  interval: string;
  timezone: string;
}

export const Execution: FC<ExecutionProps> = ({
  connectorName
}: ExecutionProps) => {
  const state = useStoreState() as IADState;
  const [cron, setCron] = useState({
    timezone: 'UTC',
    expression: ['*', '*', '*', '*', '*', '*']
  });
  const [submitting, setSubmitting] = useState(false);
  const [toast, setToast] = useState({
    show: false,
    msg: '',
    severity: 'success'
  });
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [pageDetails, setPageDetails] = useState({
    pageSize: 10,
    currentPage: 0
  });
  const [singlePageLoadingOptions, setSinglePageLoadingOptions] = useState({
    isPreviousPageDisabled: true,
    isNextPageDisabled: true,
    currentPage: 0,
  });
  const [openTasksModal, setOpenTasksModal] = useState(false);
  const [workflowRunId, setWorkflowRunId] = useState(null);
  const [workflowRunNumber, setWorkflowRunNumber] = useState(null);
  const EXECUTION_DETAILS_COLUMNS = useMemo(
    () => [
      {
        field: 'id',
        label: 'Id',
        sortable: false
      },
      {
        field: 'workflow_run_number',
        label: 'Execution Run Number',
        sortable: false,
        dsRenderCell: ({ row, value }) => {
          return (    
            <a
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setWorkflowRunId(row.id);
                setWorkflowRunNumber(row.workflow_run_number);
                setOpenTasksModal(true);
              }}
            >
              {value}
            </a>
          );
        }
      },
      {
        field: 'status',
        label: 'Status',
        sortable: false,
        dsRenderCell: ({ value }) => {
          let state: ChipProps['color'] = 'success';
    
          switch (value) {
            case 'SUCCEEDED':
            case 'Finished':
              state = 'success';
              break;
            case 'FAILED':
            case 'Stopped':
            case 'Stopping':
              state = 'error';
              break;
            case 'RUNNING':
            case 'Processing':
              state = 'primary';
              break;
            case 'WARNING':
              state = 'warning';
              break;
            case 'Queued':
            case 'Pending':
              state = 'default';
              break;
            default:
              state = 'default';
          }
    
          return <Chip label={<Typography variant='overline'>{value}</Typography>} size='medium' state={state} />
        }
      },
      {
        field: 'created_at',
        label: 'Start Date',
        sortable: true
      },
      {
        field: 'finished_at',
        label: 'End Date',
        sortable: false
      },
      {
        field: 'run_time',
        label: 'Duration (sec)',
        sortable: false
      },
      {
        field: 'error_count',
        label: 'Error Count',
        sortable: false
      }
    ], 
    []
  );
  const EXECUTION_DETAILS_TABLE_NAME = `${connectorName.replace(' ', '_')}_execution_details`;
  const [columns, setColumns] = useState(() => {
    const savedColumns = JSON.parse(localStorage.getItem(`${EXECUTION_DETAILS_TABLE_NAME}_columns`));
    if (savedColumns) {
      const merged = savedColumns.map(savedColumn => {
        const matchedColumn = EXECUTION_DETAILS_COLUMNS.find(col => col.field === savedColumn.field);
        if (matchedColumn?.dsRenderCell) {
          savedColumn.dsRenderCell = matchedColumn.dsRenderCell;
        }
        savedColumn.sortable = !!matchedColumn?.sortable;
        return savedColumn;
      });
      return merged;
    } else {
      return EXECUTION_DETAILS_COLUMNS;
    }
  });
  const [searchValue, setSearchValue] = useState(null);
  const [openExecutionModal, setOpenExecutionModal] = useState(false);
  const [openNextRunModal, setOpenNextRunModal] = useState(false);
  const [workflow, setWorkflow] = useState<Workflow>(null);
  const [executionMode, setExecutionMode] = useState('');
  const [setupError, setSetupError] = useState(false);
  const connect: Connect = (window as any).connect;
  const isLocal = window.location.host.includes('localhost');
  const workflowProxyPath = `${isLocal ? 'http://localhost:8080' : ''}/platform/gateway-proxy-v2`;
  const workflowName = state.workflow_template?.workflow_definition?.name || '';

  const getExecutions = async ({ page, rowsPerPage }) => {
    setLoading(true);
    setPageDetails({ currentPage: page, pageSize: rowsPerPage });

    try {
      const extraQueryParams = `page=${page + 1}&page_length=${rowsPerPage}&order_by=created_at&order_dir=desc`;

      const response = await Connect.proxyCall(
        `${workflowProxyPath}/workflows/workflow_runs?tags[]=${workflowName}&${extraQueryParams}`,
        'GET',
        undefined,
        { 'Zuora-Tenant-Id': (connect.tenant as any).tenant_id, 'Scope': 'Internal' }
      );

      if (!response.ok) throw Error(response.statusText);

      const { data: executions, pagination } = await response.json();
      const rows = executions.map(execution => ({
        id: execution.id,
        workflow_run_number: execution.name,
        name: execution.definitionName,
        status: execution.status,
        run_time: execution.runTime || 'N/A',
        created_at: execution.createdAt || 'N/A',
        finished_at: execution.finishedAt || 'N/A',
        error_count: execution.tasks.error
      }));

      setLoading(false);
      setSinglePageLoadingOptions({
        currentPage: page,
        isPreviousPageDisabled: page === 0,
        isNextPageDisabled: !pagination.next_page
      });
      setRows(rows);
    } catch (err) {
      Connect.log(err);
      setLoading(false);
    }
  };

  const execute = async () => {
    if (!submitting) {
      setSubmitting(true);
      setToast({...toast, show: false});
      
      try {
        let response: Response;
        const isScheduled = executionMode === 'Scheduled';

        if (isScheduled && cron.timezone === '') {
          throw new Error('Timezone cannot be empty!!! Please select a timezone and try again!');
        }

        const headers = { 'Zuora-Tenant-Id': (connect.tenant as any).tenant_id, 'Scope': 'Internal', 'Content-Type': 'application/json' };

        // update workflow with schedule
        if (isScheduled) {
          response = await Connect.proxyCall(
            `${workflowProxyPath}/workflows/versions/${workflow.active_version_id}`,
            'PUT',
            {
              workflow: {
                scheduled_trigger: isScheduled,
                interval: isScheduled ? cron.expression.join(' ').trim() : '',
                timezone: isScheduled ? cron.timezone : ''
              }
            },
            headers
          );
        } else {
          // run workflow when it's on demand
          response = await Connect.proxyCall(
            `${workflowProxyPath}/workflows/${workflow.id}/run`,
            'POST',
            {},
            headers
          );
        }

        if (response && !response.ok) throw Error(response.statusText);

        // update solution instance state if it's scheduled
        if (isScheduled) {
          setWorkflow({
            ...workflow,
            scheduled_trigger: isScheduled,
            interval: isScheduled ? cron.expression.join(' ').trim() : '',
            timezone: isScheduled ? cron.timezone : ''
          });
        } else {
          await getExecutions({ page: pageDetails.currentPage, rowsPerPage: pageDetails.pageSize });
        }

        setSubmitting(false);
        setToast({
          show: true,
          msg: isScheduled ? 'Successfully submitted a scheduled run' : 'Successfully submitted an adhoc run',
          severity: 'success'
        });
      } catch (err) {
        Connect.log(err);
        setSubmitting(false);
        setToast({
          show: true,
          msg: err.message || 'Failed to submit',
          severity: 'error'
        });
      }
    }
  };

  const onStopClickHandler = async (args: { row: any }) => {
    const { row } = args;

    if (!confirm(`Are you sure you want to stop ${row.workflow_run_number}`)) {
      return;
    }

    setLoading(true);
    try {
      const response = await Connect.proxyCall(
        `${workflowProxyPath}/workflows/${row.id}/stop`,
        'PUT',
        undefined,
        {
          'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
          'Scope': 'Internal',
          'Accept': 'application/json'
        }
      );

      if (!response.ok) throw Error(response.statusText);

      const { success } = await response.json();
      
      if (!success) throw Error('Failed to stop');

      await getExecutions({ page: pageDetails.currentPage, rowsPerPage: pageDetails.pageSize });

      setLoading(false);
      setToast({
        show: true,
        msg: `Successfully stopped ${row.workflow_run_number}`,
        severity: 'success'
      });
    } catch (err) {
      Connect.log(err);
      setLoading(false);
      setToast({
        show: true,
        msg: `Failed to stop ${row.workflow_run_number}`,
        severity: 'error'
      });
    }
  };

  const onDeleteClickHandler = async (args: { row: any }) => {
    const { row } = args;

    if (!confirm(`Are you sure you want to delete ${row.workflow_run_number}`)) {
      return;
    }
    
    setLoading(true);
    try {
      const response = await Connect.proxyCall(
        `${workflowProxyPath}/workflows/${row.id}`,
        'DELETE',
        undefined,
        {
          'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
          'Scope': 'Internal'
        }
      );

      if (!response.ok) throw Error(response.statusText);

      await getExecutions({ page: pageDetails.currentPage, rowsPerPage: pageDetails.pageSize });

      setLoading(false);
      setToast({
        show: true,
        msg: `Successfully deleted ${row.workflow_run_number}`,
        severity: 'success'
      });
    } catch (err) {
      Connect.log(err);
      setLoading(false);
      setToast({
        show: true,
        msg: 'Failed to delete',
        severity: 'error'
      });
    }
  };

  const getExecution = async (workflowRunId: string) => {
    setLoading(true);
    try {
      const response = await Connect.proxyCall(
        `${workflowProxyPath}/workflows/workflow_runs/${workflowRunId}`,
        'GET',
        undefined,
        {
          'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
          'Scope': 'Internal'
        }
      );

      if (!response.ok) throw Error(response.statusText);

      const execution = await response.json();

      if (execution.errors) {
        setRows([]);
      } else {
        setRows([{
          id: execution.id,
          workflow_run_number: execution.name,
          name: execution.definitionName,
          status: execution.status,
          run_time: execution.runTime || 'N/A',
          created_at: execution.createdAt || 'N/A',
          finished_at: execution.finishedAt || 'N/A',
          error_count: execution.tasks.error
        }]);
      }

      setLoading(false);
      setSinglePageLoadingOptions({
        currentPage: 0,
        isPreviousPageDisabled: true,
        isNextPageDisabled: true
      });
    } catch (err) {
      Connect.log(err);
      setLoading(false);
      setToast({
        show: true,
        msg: 'Failed to search an execution run',
        severity: 'error'
      });
    }
  };

  const submitButton = (
    <Box sx={{m: 1, position: 'relative'}}>
      {
        <Button
          disabled={
            submitting || 
            executionMode === '' ||
            workflow == null ||
            executionMode === 'Scheduled' && cron.timezone === ''
          }
          tooltip={executionMode === 'Scheduled' && cron.timezone === '' ? 'Timezone cannot be empty' : null}
          endIcon={executionMode === 'Scheduled' ? 'save' : 'play_circle_outline'}
          dsOnClick={() => execute()}
          body={executionMode === 'Scheduled' ? 'Save' : 'Run'}
        />
      }
      {
        submitting &&
        <CircularProgress
          size={24}
          sx={{
            color: designTokens.colors.blue500,
            position: 'absolute',
            top: '50%',
            left: '40px',
            marginTop: '-12px',
            marginLeft: '-12px'
          }}
        />
      }
    </Box>
  );

  const increaseVersion = (version: string) => {
    const [major, minor, patch] = version.split('.');

    const currentPatch = Number(patch);
    const currentMinor = Number(minor);
    const currentMajor = Number(major);

    const nextPatch = currentPatch < 9 ? currentPatch + 1 : 0;
    const nextMinor = currentPatch <= nextPatch ? currentMinor : (currentMinor < 9 ? currentMinor + 1 : 0);
    const nextMajor = currentMinor <= nextMinor ? currentMajor : currentMajor + 1; 

    return `${nextMajor}.${nextMinor}.${nextPatch}`;
  };

  const retrieveDefaultWorkflow = async () => {
    const response = await Connect.proxyCall(
      `${workflowProxyPath}/workflows?name=${workflowName}`,
      'GET',
      undefined,
      { 'Zuora-Tenant-Id': (connect.tenant as any).tenant_id, 'Scope': 'Internal' }
    );

    return response;
  };

  const getTemplate = async (workflowName: string) => {
    const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
    const options = {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'X-CSRF-Token': csrf
      })
    };

    return await window.fetch(`${state.settingsUrl.replace('.json', `/workflow/instance_template?name=${encodeURIComponent(workflowName)}`)}`, options);
  }

  const createWorkflow = async (workflowName: string) => {
    let response: Response = await getTemplate(workflowName);

    if (!response.ok) throw Error(response.statusText);

    const template = await response.json();

    response = await Connect.proxyCall(
      `${workflowProxyPath}/workflows/import?activate=true`,
      'POST',
      template,
      {
        'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
        'Content-Type': 'application/json',
        'Scope': 'Internal'
      }
    );

    return response;
  };

  const setup = async () => {
    try {
      setToast({...toast, show: false});
      setSetupError(false);

      let response: Response = await retrieveDefaultWorkflow();

      if (!response.ok) throw Error(response.statusText);

      const { data: instances } = await response.json();

      if (Array.isArray(instances) && instances.length === 0) {
        response = await createWorkflow(workflowName);

        if (!response.ok) throw Error(response.statusText);

        const instance = await response.json();

        if (instance.errors) throw Error(instance.errors?.[0]);

        setWorkflow({
          id: instance.id,
          name: instance.name,
          created_at: instance.createdAt,
          active_version_id: instance.active_version?.id || instance.id,
          scheduled_trigger: instance.scheduledTrigger,
          interval: instance.interval,
          timezone: instance.timezone
        });
      } else if (state.saved && state.update_workflow) {
        const instance = instances[0];
        const currentVersion = instance?.active_version?.version || '0.0.1';
        const nextVersion = increaseVersion(currentVersion);

        response = await getTemplate(workflowName);

        if (!response.ok) throw Error(response.statusText);

        const template = await response.json();

        if (template?.workflow) {
          template.workflow.version = nextVersion;
        }

        response = await Connect.proxyCall(
          `${workflowProxyPath}/workflows/${instance.id}/versions/import?version=${nextVersion}&activate=true`,
          'POST',
          template,
          {
            'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
            'Content-Type': 'application/json',
            'Scope': 'Internal'
          }
        );

        if (!response.ok) throw Error(response.statusText);

        const updatedInstance = await response.json();

        setWorkflow({
          id: updatedInstance.id,
          name: updatedInstance.name,
          created_at: updatedInstance.createdAt,
          active_version_id: updatedInstance.active_version?.id || updatedInstance.id,
          scheduled_trigger: updatedInstance.scheduledTrigger,
          interval: updatedInstance.interval,
          timezone: updatedInstance.timezone
        });
      } else {
        setWorkflow(instances.map(instance => ({
          id: instance.id,
          name: instance.name,
          created_at: instance.createdAt,
          active_version_id: instance.active_version?.id || instance.id,
          scheduled_trigger: instance.scheduledTrigger,
          interval: instance.interval,
          timezone: instance.timezone
        }))[0]);
      }
    } catch (error) {
      Connect.log(error);
      setSetupError(true);
    }
  };

  useEffect(() => {
    if (searchValue === null) {
      return;
    }

    if (searchValue.length === 0) {
      getExecutions({ page: 0, rowsPerPage: 10 });
      return;
    }

    const timer = setTimeout(() => getExecution(searchValue), 1000);
    
    return () => clearTimeout(timer);
  }, [searchValue]);

  useEffect(() => {
    if (state.saved) {
      setWorkflow(null);
      setup().then(() => getExecutions({ page: 0, rowsPerPage: 10 }));
    }
  }, [state.saved]);

  useEffect(() => {
    if (state.settingsHash['authentication']) {
      setup().then(() => getExecutions({ page: 0, rowsPerPage: 10 }));
    }
  }, []);

  try {
    if (!state.active) {
      return (
        <Alert center variant='outlined' severity='warning' body='You must enable integration in Authentication tab first' open={true} />
      );
    }
  
    if (!state.settingsHash['authentication']) {
      return (
        <Alert center variant='outlined' severity='warning' body='Configure Authentication and save' open={true} />
      );
    }
  
    if (setupError) {
      return (
        <Alert center variant='outlined' severity='error' body='Failed to set up your connector. Please contact admin!' open={true} />
      );
    }
  
    if (!workflow) {
      if (!state.saved) {
        return (
          <Alert center variant='outlined' severity='warning' body={`${state.settingsHash['authentication'] ? 'Please wait while we are setting up your connector' : 'Save Authentication to continue'}`} open={true} />
        );
      } else {
        return (
          <Alert center variant='outlined' severity='warning' body={`Please wait while we are ${state.saved ? 'updating' : 'setting up'} your connector`} open={true} />
        );
      }
    }
  } catch (err) {
    Connect.log(err);
    return (
      <Alert center variant='outlined' severity='error' body='An unexpected error has occurred. Please contact admin for technical support!' open={true} />
    );
  }

  return (
    <Grid container spacing={2}>
      { toast.show && <ToastController severity={toast.severity as any} message={toast.msg} /> }
      <TasksModal workflowRunId={workflowRunId} workflowRunNumber={workflowRunNumber} open={openTasksModal} setOpenTasksModal={setOpenTasksModal} />
      <NextRunModal open={openNextRunModal} setOpenNextRunModal={setOpenNextRunModal} workflow={workflow} setWorkflow={setWorkflow} />
      <ExecutionModal
        open={openExecutionModal}
        setOpenExecutionModal={(open) => {
          setOpenExecutionModal(open);
          setExecutionMode('');
        }}
        submitButton={submitButton}
      >
        <Grid item xs={12}>
          <Card
            id='execution-top-ui'
            variant={'outlined'}
            body={
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    select
                    label={<b>Execution Mode</b>}
                    value={executionMode}
                  >
                    {
                      ['On-Demand', 'Scheduled'].map((option: string, idx: number) => (
                        <MenuItem
                          key={idx}
                          value={option}
                          onClick={() => {
                            setExecutionMode(option);
                            if (workflow) {
                              setCron({
                                ...cron,
                                timezone: workflow.timezone || 'UTC',
                                expression: workflow.interval ? workflow.interval.split(' ') : ['*', '*', '*', '*', '*', '*']
                              });
                            }
                          }}
                        >
                          {option}
                        </MenuItem>
                      ))
                    }
                  </TextField>
                </Grid>
                {
                  executionMode === 'Scheduled' &&
                  <Grid item xs={12}>
                    <CronBuilder
                      cron={cron}
                      setCron={setCron}
                    />
                  </Grid>
                }
              </Grid>
            }
          />
        </Grid>
      </ExecutionModal>
      <Grid item xs={12}>
        <Card
          id='execution-bottom-ui'
          titleBar
          header='Execution Details'
          headerAction={
            <>
              <Button icon='settings' tooltip='Configure Run' dsOnClick={() => setOpenExecutionModal(true)} />
              <Button icon='schedule' tooltip='See Next Runs' dsOnClick={() => setOpenNextRunModal(true)} />
            </>
          }
          body={
            <Table
              loading={loading}
              columns={columns}
              rows={rows}
              uniqueKey='execution-table'
              tableActions={[
                {
                  icon: 'refresh',
                  tooltip: 'Reload Table',
                  onClick: () => getExecutions({ page: pageDetails.currentPage, rowsPerPage: pageDetails.pageSize })
                }
              ]}
              rowActions={[
                {
                  dsGetActionData: (row) => ({
                    icon: 'stop_circle',
                    tooltip: 'Stop',
                    disabled: ['Finished', 'Stopped', 'Stopping'].includes(row.status as string),
                    onClick: onStopClickHandler
                  })
                },
                {
                  dsGetActionData: (row) => ({
                    icon: 'delete_outline',
                    tooltip: 'Delete',
                    disabled: ['Queued', 'Processing', 'Pending', 'Stopping'].includes(row.status as string),
                    onClick: onDeleteClickHandler
                  })
                }
              ]}
              rowDisplayOptions={{
                hoverEffect: false
              }}
              rowsPerPage={pageDetails.pageSize}
              rowsPerPageOptions={[5, 10, 15, 20, 30, 45]}
              dsOnPage={getExecutions}
              hideTotalRows
              singlePageLoadingOptions={singlePageLoadingOptions}
              orderable
              {...{searchable: true}}
              dsOnColumnsChange={(columns) => {
                localStorage.setItem(`${EXECUTION_DETAILS_TABLE_NAME}_columns`, JSON.stringify(columns));
              }}
              dsOnSearchChange={(args) => setSearchValue(args)}
              showNoRowsMessage={rows?.length === 0}
              searchPlaceholder='Type execution run number/id to filter results...'
            />
          }
        />
      </Grid>
    </Grid>
  );
};